import { FeatureToggleConfig } from './src/types';

export const config = {
  // Here as a default / example
  quote_builder: {
    defaultValue: 'control',
    variants: ['control', 'variant-1'],
    experiments: [
      {
        name: 'quote-builder',
        distribution: {
          control: 0.5,
          'variant-1': 0.5,
        },
      },
    ],
  },
  new_meetings_tab: {
    defaultValue: false,
    enabledForEnvironments: ['development', 'staging', 'sandbox'],
  },
  post_briefing_signup: {
    defaultValue: true,
  },
} as const satisfies Record<string, FeatureToggleConfig>;
