import { isEmptyPlainObject, merge } from '@sortlist-frontend/utils';
import React, { createContext, PropsWithChildren, useContext } from 'react';

import { config } from '../defaults';
import { FeatureFlagSetup } from './types';

const FeatureFlagsContext = createContext<FeatureFlagSetup>({} as FeatureFlagSetup);

const useFeatureFlagContextValue = () => {
  const context = useContext(FeatureFlagsContext);
  if (isEmptyPlainObject(context)) {
    throw new Error(`useIsFeatureActive must be used within an FeatureFlagsProvider`);
  }
  return context as FeatureFlagSetup;
};

export const useMergedFeatureFlagConfig = () => {
  const configOverride = useFeatureFlagContextValue().configOverride;
  return merge({}, config, configOverride);
};

export const useAbTestCookieValue = () => {
  return useFeatureFlagContextValue().abTestCookie;
};

export const FeatureFlagsProvider = ({
  children,
  configOverride,
  abTestCookie,
}: PropsWithChildren<FeatureFlagSetup>) => {
  return (
    <FeatureFlagsContext.Provider value={{ configOverride, abTestCookie }}>{children}</FeatureFlagsContext.Provider>
  );
};
