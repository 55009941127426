// TODO convert functions to hooks usage via `useLocalStorage`

import { isBrowser } from './browser';

// Some browsers have very hard privacy settings and doesn't allow
// to manipulate localStorage. It should never make the browser crash.
// The try/catch are making it safe.
/**
 * @deprecated use `useLocalStorage` hook instead
 */
export const getStorage = (key: string): string | null | false => {
  if (!isBrowser()) return false;

  try {
    return localStorage.getItem(key);
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead
 */
export const removeStorage = (key: string) => {
  if (!isBrowser()) return false;

  try {
    return localStorage.removeItem(key);
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead
 */
export const getOrSetStorage = (key: string, value: string): boolean => {
  if (!isBrowser()) return false;

  try {
    localStorage.getItem(key) || localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead
 */
export const setStorage = (key: string, value: string): boolean => {
  if (!isBrowser()) return false;

  try {
    localStorage.setItem(key, value);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead with `local-storage` event
 * see https://usehooks-ts.com/react-hook/use-local-storage
 */
export const setStorageWithEvent = (key: string, value: string): boolean => {
  if (!isBrowser()) return false;

  try {
    const event = new Event(key);
    localStorage.setItem(key, value);
    window.dispatchEvent(event);
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead see https://usehooks-ts.com/react-hook/use-local-storage
 */
export const hasStorage = () => {
  if (!isBrowser()) return false;

  try {
    localStorage.setItem('test', 'test');
    localStorage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};

/**
 * @deprecated use `useLocalStorage` hook instead with `local-storage` event
 * see https://usehooks-ts.com/react-hook/use-local-storage
 */
export const listenStorage = (key: string, callback: () => void): void | false => {
  if (!isBrowser()) return false;

  try {
    window.addEventListener(key, (event) => {
      return event.type === key && callback();
    });
    window.onstorage = (event) => {
      return event.key === key && callback();
    };
  } catch (e) {
    return false;
  }
};
