import { getOrSetStorage, isBrowser, setStorage } from '@sortlist-frontend/utils';

import { SourceData } from '_components/Briefing/types';

type StringObject = { [key: string]: string };

const TRACKED_PARAMS = ['gclid', 'msclkid', 'utm_source', 'utm_medium', 'utm_cid', 'utm_k', 'utm_mt', 'utm_content'];

const toCamel = (string: string) => {
  return string
    .replace(/\_(.)/g, ($1) => $1.toUpperCase())
    .replace(/\_/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
};

const decodeValue = (value: string) => {
  return !value ? '' : decodeURIComponent(value.replace(/\+/g, ' ')); // Google uses + as space
};

const parseSearch = (search: string) => {
  if (!search) return {};

  return search
    .replace(/\/*$/, '') // removes trailing slash
    .split('&')
    .reduce((acc: StringObject, param: string): StringObject => {
      const parts = param.split('=');
      const key = toCamel(parts[0]);
      const value = decodeValue(parts[1]);
      acc[key] = value;
      return acc;
    }, {});
};

export const getEntryParams = () => {
  const params = parseSearch(location.search.substring(1));
  const keys = TRACKED_PARAMS.map((str) => toCamel(str)).filter((key) => params[key]);
  return keys.reduce((acc: StringObject, key: string): StringObject => {
    acc[key] = params[key];
    return acc;
  }, {});
};

export const setEntryParams = () => {
  if (!isBrowser()) return;
  getOrSetStorage('sourceParams', JSON.stringify(getEntryParams()));
};

export const setEntryUrls = () => {
  if (!isBrowser()) return;
  // the 'absolute' are set only once at first entry
  getOrSetStorage('absoluteEntryUrl', JSON.stringify(location.href));
  getOrSetStorage('absoluteEntryDate', JSON.stringify(new Date().toISOString()));
  // the 'session' is set each time user come from outside SL
  if (document.referrer?.match(/(sortlist|sort-list)/)) return;
  setStorage('sessionEntryUrl', JSON.stringify(location.href));
};

export const getSourceObject = (): SourceData => {
  if (!isBrowser()) return {};
  try {
    const absoluteEntryUrl = localStorage.getItem('absoluteEntryUrl');
    const absoluteEntryDate = localStorage.getItem('absoluteEntryDate');
    const sessionEntryUrl = localStorage.getItem('sessionEntryUrl');
    const sourceParams = localStorage.getItem('sourceParams');
    return {
      ...(absoluteEntryUrl && { absoluteEntryUrl: JSON.parse(absoluteEntryUrl) }),
      ...(absoluteEntryDate && { absoluteEntryDate: JSON.parse(absoluteEntryDate) }),
      ...(sessionEntryUrl && { sessionEntryUrl: JSON.parse(sessionEntryUrl) }),
      landingUrl: location.href,
      ...(sourceParams && JSON.parse(sourceParams)),
    };
  } catch (e) {
    // Some browsers with hard privacy settings don't allow access to localStorage,
    // so we provide a gracefull fallback
    return {
      absoluteEntryUrl: location.href,
      absoluteEntryDate: new Date().toISOString(),
      sessionEntryUrl: location.href,
      landingUrl: location.href,
      ...getEntryParams(),
    };
  }
};
