import { useQuery } from '@tanstack/react-query';

import { api as apiPublicApi } from '_core/api/api-public-api';
import { useNavigationContext } from '_core/context/navigation-context';
import { getOffsetAndLimitByPage } from '_features/longtail/components/Pagination/utils';
import { Agencies, Links, Page, Works } from '_types/public-api';

import { getSortParam } from './public-api.ssr.repo';

export type Reviews = { reviewsCount: number; reviewsAverage: string };
const DEFAULT_LOCAL_ORIGIN_DOMAIN = 'www.sortlist-test.com';
const ORIGIN =
  process.env.NODE_ENV === 'development' || process.env.USE_DEFAULT_LOCAL_ORIGIN_DOMAIN
    ? process.env.NEXT_PUBLIC_APP_URL!
    : null;

// TO FIX: the api route doing this in the old public-app was broken and is now removed.
// For now, it's good enough to hardcode these values and change from time to time.
// In the future, we should reimplement correctly.
export const TRUSTPILOT_DATA = { reviewsCount: 516, reviewsAverage: '0.9' };

// On Sandboxes we don't have data (Seeds) for public-api
// In order to have data we connect to staging with the following function
const getStagingOrigin = (origin: string) => {
  // Scenario 1: https://pr-public-ui-3012-public.sandbox.sortlist.cloud
  // This should request data from staging with: https://www.sortlist-test.com

  // Scenario 2: https://be-pr-public-ui-3012-public.sandbox.sortlist.cloud
  // Link should be: https://www.sortlist-test.be

  // Scenario 3: https://localeOtherThanBe-pr-public-ui-3012-public.sandbox.sortlist.cloud
  // Link should be: https://www-localeOtherThanBe.sortlist-test.com

  const { hostname } = new URL(origin);
  const domainKey = hostname.split('-')[0];

  if (domainKey === 'pr') {
    return 'https://www.sortlist-test.com';
  } else if (domainKey === 'be') {
    return 'https://www.sortlist-test.be';
  } else {
    return `https://www-${domainKey}.sortlist-test.com`;
  }
};

export const getPublicApiRequestLink = (origin: string, locale: string, pathname: string) => {
  const cleanOrigin = origin.includes('public.sandbox.sortlist.cloud') ? getStagingOrigin(origin) : origin;
  const cleanPath = pathname.replace('/embed', '').split('?')[0];
  const cleanUrl = `${cleanOrigin}/${locale}/${encodeURIComponent(cleanPath)}`;
  if (ORIGIN != null) {
    return cleanUrl.replace(ORIGIN, DEFAULT_LOCAL_ORIGIN_DOMAIN).replace('https://', '');
  }
  return cleanUrl.replace('https://', '');
};

export const getPublicApiRequestHomeLink = (origin: string, locale: string) => {
  const cleanOrigin = origin.includes('public.sandbox.sortlist.cloud') ? getStagingOrigin(origin) : origin;
  const cleanUrl = `${cleanOrigin}/${locale}/${encodeURIComponent('/')}`;
  if (ORIGIN != null) {
    return cleanUrl.replace(ORIGIN, DEFAULT_LOCAL_ORIGIN_DOMAIN).replace('https://', '');
  }
  return cleanUrl.replace('https://', '');
};

const WORKS_LIMIT = 3;

export class PublicApiRepo {
  public static publicApiUrl = '';
  public static publicApiNavBarUrl = '';

  getPage = async ({ page }: { page: string }): Promise<Page> => {
    const url = `/pages/${page}`;
    return apiPublicApi.get(url).then((data) => data.data);
  };

  getAgencies = async ({
    page,
    section,
    number,
    sort,
    limit,
    fieldsNeeded,
  }: {
    page: string;
    section: 'organic' | 'paid';
    number?: string;
    sort?: string;
    limit?: number;
    fieldsNeeded?: string; // comma separated fields that you want the api to return, if nothing provided all fields will be returned.
  }): Promise<Agencies> => {
    // IMPORTANT: if you adapt the logic for offset and limit here please inform #chapter-ai-data
    // so that they can adapt the leadforensic script
    const { offset, limit: staticLimit } = getOffsetAndLimitByPage(parseInt(number || '') || 1);

    const filters = `&filter[section][]=${section}&filter[section][]=manual`;

    const sortParam = getSortParam(sort, section);

    const fields = fieldsNeeded ? `&fields%5Bagency%5D=${fieldsNeeded}` : '';

    const url = `/pages/${page}/pages_agencies?page[offset]=${offset}&page[limit]=${
      limit || staticLimit
    }${sortParam}${fields}${filters}`;

    return apiPublicApi.get(url).then((data) => data.data);
  };

  getLinks = async ({ page, queryParam }: { page: string; queryParam?: string }): Promise<Links> => {
    let composedQueryParams = `fields%5Bpage%5D=host,locale,path`;
    if (queryParam) composedQueryParams = `${composedQueryParams}&${queryParam}`;

    const url = `/pages/${page}/links?${composedQueryParams}`;
    return apiPublicApi.get(url).then((data) => data.data);
  };

  getWorks = async ({ page, limit = WORKS_LIMIT }: { page: string; limit?: number }): Promise<Works> => {
    const url = `/pages/${page}/pages_works?include=work.agency&page[limit]=${limit}`;
    return apiPublicApi.get(url).then((data) => data.data);
  };

  getReviews = async (): Promise<Reviews> => {
    return new Promise((resolve) => resolve(TRUSTPILOT_DATA));
  };
}

export const publicApiRepo = new PublicApiRepo();

export const cacheKey = {
  page: (url: string) => `page-${url}`,
  agencies: (url: string, section: 'organic' | 'paid') => `agencies-${url}-${section}`,
  links: (url: string) => `links-${url}`,
  works: (url: string) => `works-${url}`,
  reviews: 'reviews',
};

export function useGetPage(url: string) {
  return useQuery({
    queryKey: [cacheKey.page(url)],
    queryFn: () => {
      return publicApiRepo.getPage({ page: url });
    },
    enabled: false,
  });
}

export function useGetAgencies(url: string, section: 'organic' | 'paid') {
  const { query } = useNavigationContext();
  const { sort, position = '1' } = query;

  return useQuery({
    queryKey: [cacheKey.agencies(url, section)],
    queryFn: () => {
      return publicApiRepo.getAgencies({ page: url, section, sort: sort as string, number: position as string });
    },
    enabled: false,
  });
}

export function useGetLinks(url: string) {
  return useQuery({
    queryKey: [cacheKey.links(url)],
    queryFn: () => {
      return publicApiRepo.getLinks({ page: url });
    },
    enabled: false,
  });
}

export function useGetWorks(url: string) {
  return useQuery({
    queryKey: [cacheKey.works(url)],
    queryFn: () => {
      return publicApiRepo.getWorks({ page: url });
    },
    enabled: false,
  });
}

export function useGetReviews() {
  return useQuery({
    queryKey: [cacheKey.reviews],
    queryFn: () => {
      return publicApiRepo.getReviews();
    },
    enabled: false,
  });
}
