import { SnackbarProvider } from '@sortlist-frontend/design-system';
import { FeatureFlagsProvider, useExtractFeatureFlagOverrides } from '@sortlist-frontend/feature-flags';
import { Monitor } from '@sortlist-frontend/mlm';
import { SharedComponentsProvider } from '@sortlist-frontend/shared-components';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import { DehydratedState, HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DefaultSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState, type FC } from 'react';

import { GlobalErrorFallback } from '_components/error/error-boundaries/';
import { seoConfig } from '_config/next-seo.config';
import { NavigationContextProvider } from '_core/context/navigation-context';
import { setEntryParams, setEntryUrls } from '_core/tracking/entry-url-params-storer';

const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // don't fetch when window is focused
      retry: false, // if it fails don't retry (you can configure this on a specific resource)
      // For now we fetch in ssr and then we fetch imediately after hydration on the client
      // staleTime: 5 * 1000, // this should avoid fetching on client imediatly
    },
  },
};

type Props = {
  children: ReactNode;
  dehydratedState?: DehydratedState;
};

const AppProviders: FC<Props> = (props) => {
  const [queryClient] = useState(() => new QueryClient(queryClientOptions));
  const router = useRouter();
  const { pathname, asPath, query, push } = router;
  const { configOverride } = useExtractFeatureFlagOverrides(router.query);
  const { t, i18n } = useTranslation(['common']);

  useEffect(() => {
    // This is not very clear for human devs, it just does set first page
    // entry in local storage... ask Mike
    setEntryUrls();
    setEntryParams();
  }, []);

  return (
    <Monitor.ErrorBoundary fallback={GlobalErrorFallback(t)} showDialog>
      <SharedComponentsProvider t={t} Trans={Trans} locale={i18n.language}>
        <DefaultSeo {...seoConfig.defaultNextSeo} />
        {/* Passing down err prop, see https://github.com/vercel/next.js/issues/8592 */}
        <QueryClientProvider client={queryClient}>
          <HydrationBoundary state={(props as unknown as { dehydratedState: unknown }).dehydratedState}>
            <NavigationContextProvider pathname={pathname} asPath={asPath} query={query} push={push}>
              <SnackbarProvider>
                <FeatureFlagsProvider configOverride={configOverride}>{props.children}</FeatureFlagsProvider>
              </SnackbarProvider>
            </NavigationContextProvider>
          </HydrationBoundary>
        </QueryClientProvider>
      </SharedComponentsProvider>
    </Monitor.ErrorBoundary>
  );
};

export { AppProviders };
